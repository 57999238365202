import React from "react";
import './App.css';

function About() {
  return (
    <div>
      <h1>About page</h1>
    </div>
  );
}

export default About;